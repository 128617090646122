var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
var homeQuickGivingPreFooter = true;
var countUpStats = true;
var countUpStatsDuration = 2000;
var subsiteHijackMainNav = ['rotary'];
var subsiteHijackMainLogoClickDestination = ['rotary'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;


// Reword donation form
$(".formQuestion.donationComment > label").text("Tell us why you are donating.");

// Search container - Open on click
$('.searchContainer input').click(function(){
  $(this).parent().addClass('open');
});


// if($(".cc-banner").is(":visible")) {
if($(".cc-banner").css('display') != 'none') {
  $("#chatamo-roundel").css("margin-bottom", "72px");
}